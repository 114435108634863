<template>
  <div class="gird">
    <Head v-bind:data="HeadSetting" />
    <section class="container">
      <h1 class="static__page__title">{{title}}</h1>
      <div class="itemBox no-ma-top">
        <h2 class="boxTitle t-center">バージョン v.0.10</h2>
      </div>
    </section>
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";

export default {
  data() {
    return {
      HeadSetting: {
        Left: 'backBtn',
        Right: 'mypage',
        Center: 'search'
      },
      title: 'バージョン',
      description: 'TOKYOWAVEのバージョン情報ページです。。'
    };
  },
  components: {
    Head,
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
}
</script>

<style scoped>
.static__page__title{
  text-align: center;
  margin: 32px 0;
}

.item__detail--spec--table dt{
  width: 30%;
}

.static__txt{
  font-size: .85rem;
}

.static__txt.outer{
  padding: 16px;
}
</style>
